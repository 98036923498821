'use client';

import { Box, chakra, Image } from '@chakra-ui/react';
import graph from '../media/graph2.png';
import { motion } from 'framer-motion';

const variants = () => ({
  offscreen: {
    y: 0,
    opacity: 0,
    scale: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      bounce: 0.5,
      duration: 0.5,
      delay: 0,
    },
  },
});

export default function Tokenomics() {
  return (
    <Box maxW="7xl" mx={'auto'} pb={25} px={{ base: 2, sm: 12, md: 17 }}>
      {/*       <Box pb={25}>
        <Text fontStyle={'italic'} fontFamily={'body'} fontSize={'25px'}>
          In the heart of the 1848 Gold Rush in California, the 'Ethereum Gold
          Mining Company' embarked on an extraordinary journey. Their mining
          venture led to the discovery of a seemingly endless reservoir of gold
          and silver hidden deep within the Sierra Nevada Mountains. Miners
          toiled day and night, unearthing unparalleled riches.{' '}
          <p style={{ margin: '25px 0' }}>
            {' '}
            The company's reputation grew, attracting not only experienced
            miners but also savvy investors who recognized the immense potential
            of this venture. Shareholders in the 'Ethereum Gold Mining Company'
            watched as their investments multiplied tenfold, and they became
            some of the wealthiest individuals in the nation.
          </p>{' '}
          <p style={{ margin: '25px 0' }}>
            {' '}
            With their newfound wealth, these shareholders didn't merely rest on
            their laurels. They invested in education, founding schools and
            libraries in the Gold Rush towns, ensuring that future generations
            would have access to knowledge.
          </p>{' '}
          <p style={{ margin: '25px 0' }}>
            Some used their riches to support groundbreaking scientific
            research, contributing to the advancement of technology and
            medicine. As the Gold Rush era drew to a close, the 'Ethereum Gold
            Mining Company' left a legacy far beyond their extraordinary wealth.
          </p>
          They were remembered not only for their fortune but for their
          contributions to education, science, and society at large. Their story
          served as a testament to the transformative power of discovery and the
          positive impact that responsible stewardship of wealth can have on a
          community and a nation.
        </Text>
      </Box> */}
      <chakra.h1
        textAlign={'center'}
        fontSize={'5xl'}
        py={10}
        fontWeight={'bold'}
        fontFamily={'Poppins'}
        marginTop={'15rem'}
      >
        Tokenomics
      </chakra.h1>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={variants()}
        style={{ margin: '0 auto' }}
      >
        <Image src={graph} width={'90%'} margin={'0 auto'} />
      </motion.div>
    </Box>
  );
}
