import React, { useEffect, useState } from 'react';

import './curtain.scss';
import curtainLeft from '../media/goldenstampethgold_01.png';
import curtainRight from '../media/goldenstampethgold_02.png';
import bg from '../media/vintagepaper.jpg';
import bg2 from '../media/miners.png';

const CurtainWrapper = ({ children }) => {
  const [slideCurtain, setSlideCurtain] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSlideCurtain(true);
    }, 500);
  }, []);

  const leftTransformStyle = {
    transform: `translateX(${slideCurtain ? '-100%' : '0%'})`,
    background: `url(${bg})`,
  };
  const rightTransformStyle = {
    transform: `translateX(${slideCurtain ? '100%' : '0%'})`,
    background: `url(${bg})`,
  };

  const curtainImageStyle = (img, pos) => ({
    background: `url(${img})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: pos,
    width: '100%',
    height: '100%',
    filter: 'brightness(0.7)',
  });

  console.log('SLIDE CURTAIN', slideCurtain);
  return (
    <div class="curtain">
      <div class="curtain__wrapper" style={{ background: '##B88F31' }}>
        <div
          class="curtain__panel curtain__panel--left"
          style={leftTransformStyle}
        >
          <div style={curtainImageStyle(curtainLeft, 'right')} />
        </div>

        <div
          class="curtain__content"
          style={{
            background: `linear-gradient(rgba(0, 0, 0, 0.70), rgba(0, 0, 0, 0.70)), url(${bg2}) no-repeat center center / cover`,
            backgroundColor: '#B88F31',
          }}
        >
          {children}
        </div>

        <div
          class="curtain__panel curtain__panel--right"
          style={rightTransformStyle}
        >
          <div style={curtainImageStyle(curtainRight, 'left')} />
        </div>
      </div>
    </div>
  );
};

export default CurtainWrapper;
