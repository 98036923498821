import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Nav from './components/Nav';
import Hero from './components/Hero';
import Explained from './components/Explained';
import HowItWorks from './components/HowItWorks';
import ExplainedTwo from './components/ExplainedTwo';
import Footer from './components/Footer';
import Tokenomics from './components/Tokenomics';
import CurtainWrapper from './components/Curtain';
import Roadmap from './components/Roadmap';

// 2. Add your color mode config
const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

// 3. extend the theme
const theme = extendTheme({ config });

function App() {
  return (
    <ChakraProvider theme={theme}>
      <CurtainWrapper>
        <Nav />
        <Hero />
        <Roadmap />
        <Tokenomics />
        <HowItWorks />
        <Explained />
        <ExplainedTwo />
        <Footer />
      </CurtainWrapper>
    </ChakraProvider>
  );
}

export default App;
