'use client';

import {
  Box,
  chakra,
  Container,
  Image,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaEthereum, FaTelegram, FaTwitter } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import signatureField from '../media/signaturefield.png';

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box
      bg="transparent"
      color={useColorModeValue('gray.700', 'gray.200')}
      mt={35}
      justifyContent={'space-between'}
      style={{ width: '100%' }}
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'space-between', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
        style={{ width: '100%' }}
      >
        <Image
          src={signatureField}
          width={450}
          style={{ filter: 'invert(1)' }}
        />
        <Text>© 2023 EGMC. All rights reserved</Text>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Twitter'} href={'https://twitter.com/EGMC_eth'}>
            <FaTwitter />
          </SocialButton>
          <SocialButton label={'Telegram'} href={'https://t.me/EGMC_eth'}>
            <FaTelegram />
          </SocialButton>
          <SocialButton
            label={'Ethereum'}
            href={
              'https://etherscan.io/address/0xf70e5e46a50bba54917d9cb7aeb8b83610bcd969'
            }
          >
            <FaEthereum />
          </SocialButton>
          <SocialButton label={'Mail'} href={'mailto:egmc.corp@gmail.com'}>
            <FiMail />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
}
