'use client';
import { motion } from 'framer-motion';
import { Box, Container, Heading, Image, Stack } from '@chakra-ui/react';
import stamp1848 from '../../media/1848transp.png';
import goldStamp from '../../media/goldenstamp2.png';

const variants = (rotate, delay) => ({
  offscreen: {
    y: 0,
    opacity: 0,
    scale: 0,
  },
  onscreen: {
    y: 0,
    rotate: rotate,
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      bounce: 0.5,
      duration: 0.5,
      delay: delay,
    },
  },
});

export default function HowItWorks() {
  return (
    <Box p={7} marginTop={'15rem'}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            variants={variants(0, 0.1)}
            style={{ marginBottom: '50px' }}
          >
            <Image
              src={stamp1848}
              width={250}
              margin="0 auto"
              style={{ filter: 'invert(1)' }}
              alt="1848"
            />
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            variants={variants(0, 0.2)}
            style={{
              marginBottom: '50px',
              fontFamily: 'Poppins',
            }}
          >
            The Golden Rule: He who has the gold makes the rules.
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            variants={variants(0, 0.3)}
            style={{ marginBottom: '50px' }}
          >
            <Image src={goldStamp} width={250} margin="0 auto" alt="1848" />
          </motion.div>
        </Heading>
        {/* <Text fontSize={{ base: 'sm', sm: 'lg' }} pt={55}>
          The rewards are collected from the taxes of the token generated upon
          buying and selling. Users can purchase Ticket NFTs to gain a chance to
          win the{' '}
          <Text as="span" color="green.400">
            reward pot in ETH
          </Text>
          . Users can purchase Owner NFTs to earn a % of all ticket sales{' '}
          <Text as="span" color="pink.400">
            in the native $888 token
          </Text>
          .
        </Text> */}
      </Stack>

      {/*  <Container maxW={'5xl'} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            heading={'Revenue sharing'}
            icon={<Icon as={FcDonate} w={10} h={10} />}
            description={
              'Every holder of an Owner NFTs received a % of all ticket sales'
            }
            href={'#'}
          />
          <Card
            heading={'Rewards'}
            icon={<Icon as={FcLightAtTheEndOfTunnel} w={10} h={10} />}
            description={
              'Get a chance to win huge rewards in ETH by purchasing Ticket NFTs'
            }
            href={'#'}
          />
        </Flex>
      </Container> */}
    </Box>
  );
}
