'use client';

import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';

import { FaMoneyBill, FaMoneyCheck, FaCertificate } from 'react-icons/fa';
import shares from '../media/shareholdernft.png';
import { motion } from 'framer-motion';

const variants = () => ({
  offscreen: {
    y: 0,
    opacity: 0,
    scale: 0,
  },
  onscreen: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      bounce: 0.5,
      duration: 0.5,
      delay: 0,
    },
  },
});

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text textAlign={'left'} fontWeight={600}>
        {text}
      </Text>
    </Stack>
  );
};

export default function ExplainedTwo() {
  return (
    <Container maxW={'5xl'} pb={60}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={variants()}
          style={{ margin: '0 auto' }}
        >
          <Flex>
            <Image
              rounded={'md'}
              alt={'feature image'}
              src={shares}
              objectFit={'cover'}
              width={350}
            />
          </Flex>
        </motion.div>
        <Stack spacing={4}>
          <Button
            className="egmc_button"
            as={'a'}
            href="https://docs.egmc.info/historic-artifacts/shareholder-certificates"
            maxWidth={'300px'}
            margin={'0 auto'}
          >
            SHAREHOLDERS
          </Button>
          <Heading fontFamily={'Poppins'}>
            Holders of 'Shares Certificates'
          </Heading>
          <Text fontFamily={'Poppins'} color={'white'} fontSize={'lg'}>
            The 'Shareholders' who have purchased shares of the Company receive
            a % of all the Tools sold. The 'Shareholders' also get a % of the
            liquidity that is added to the pool. They can choose to claim that
            liquidity as they desire.
          </Text>
          <Text fontFamily={'Poppins'} color={'yellow.500'} fontSize={'lg'}>
            IMPORTANT: These are NFT's, that are tradable and purchased with
            ETH.
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }
          >
            <Feature
              icon={<Icon as={FaMoneyBill} color={'yellow.500'} w={5} h={5} />}
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={
                'Get a % of all Tool sales and get a % of the entire liqudity pool'
              }
            />
            <Feature
              icon={<Icon as={FaMoneyCheck} color={'yellow.500'} w={5} h={5} />}
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'Shares get more expensive with every mint'}
            />
            <Feature
              icon={
                <Icon as={FaCertificate} color={'yellow.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'Limited amount of 1000 Shareholder NFTs'}
            />
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
}
