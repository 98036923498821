'use client';

import { Box, Container, Text, Stack, Image } from '@chakra-ui/react';

import logo from '../media/logotransp.png';
import signature from '../media/vitaliksignature.png';

export default function Hero() {
  return (
    <>
      <Container maxW={'3xl'}>
        <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 15, md: 30 }}
        >
          <Image
            src={logo}
            alt="logo"
            style={{ width: '90%' }}
            margin="0 auto"
          />

          <Text
            fontStyle={'italic'}
            fontFamily={'Poppins'}
            fontSize={{ base: '1xl', sm: '2xl', md: '4xl' }}
          >
            "Gold! Gold! Gold from the American River!"
          </Text>
          <Image
            src={signature}
            width={250}
            margin="0 auto"
            style={{
              filter: 'invert(1)',
            }}
          />
        </Stack>
      </Container>
    </>
  );
}
