'use client';

import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';

import { FaMoneyBill, FaMoneyCheck, FaCertificate } from 'react-icons/fa';
import tools from '../media/3nfts.png';
import { motion } from 'framer-motion';

const variants = () => ({
  offscreen: {
    y: 0,
    opacity: 0,
    scale: 0,
  },
  onscreen: {
    y: 0,
    scale: 1,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.5,
      duration: 0.5,
      delay: 0,
    },
  },
});

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text textAlign={'left'} fontWeight={600}>
        {text}
      </Text>
    </Stack>
  );
};

export default function Explained() {
  return (
    <Container maxW={'5xl'} py={60}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Button
            className="egmc_button"
            as={'a'}
            href="https://docs.egmc.info/historic-artifacts/mining-tools"
            maxWidth={'300px'}
            margin={'0 auto'}
          >
            MINERS
          </Button>
          <Heading fontFamily={'Poppins'}>
            Miners need 'Tools' to find the Gold
          </Heading>
          <Text fontFamily={'Poppins'} color={'white'} fontSize={'lg'}>
            The lucky 'Miners' who have found the EGMC mine, need to get to work
            by getting their 'Tools' and starting to mine the 'Gold' and
            'Silver' in the Mine. To be a 'Miner' and purchase 'Tools' you will
            use $EMGC.
          </Text>
          <Text fontFamily={'Poppins'} color={'yellow.500'} fontSize={'lg'}>
            IMPORTANT: These are NOT NFT's, they are bound wallet-bound.
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }
          >
            <Feature
              icon={<Icon as={FaMoneyBill} color={'yellow.500'} w={5} h={5} />}
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'Mine with Tools to get Gold and Silver'}
            />
            <Feature
              icon={<Icon as={FaMoneyCheck} color={'yellow.500'} w={5} h={5} />}
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'The Mine has both Gold ($ETH) and Silver ($EGMC)'}
            />
            <Feature
              icon={
                <Icon as={FaCertificate} color={'yellow.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'There are 3 different tools for Miners'}
            />
          </Stack>
        </Stack>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={variants()}
          style={{
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Flex>
            <Image
              alt={'feature image'}
              src={tools}
              objectFit={'cover'}
              width={500}
            />
          </Flex>
        </motion.div>
      </SimpleGrid>
    </Container>
  );
}
