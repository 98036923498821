export const NAV_ITEMS = [
  {
    label: 'The Telegram',
    href: 'https://t.me/EGMC_eth',
  },

  {
    label: 'The Twitter',
    href: 'https://twitter.com/EGMC_eth',
  },
  {
    label: 'The Documentation',
    href: 'https://docs.egmc.info',
  },
];
