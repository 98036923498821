import { chakra } from '@chakra-ui/react';
import {
  FaExpand,
  FaMoneyBill,
  FaMountain,
  FaNetworkWired,
  FaSun,
  FaTools,
  FaWpexplorer,
} from 'react-icons/fa';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const Roadmap = () => (
  <>
    <chakra.h1
      textAlign={'center'}
      fontSize={'5xl'}
      pb={10}
      pt={'10rem'}
      fontWeight={'bold'}
      fontFamily={'Poppins'}
      marginTop={'1rem'}
    >
      Roadmap
    </chakra.h1>
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          border: '2px solid #b88f31',
          color: '#b88f31',
          background: 'rgba(184,143,49,.086)',
        }}
        contentArrowStyle={{ borderRight: '7px solid  #b88f31' }}
        iconStyle={{ background: '#b88f31', color: '#fff' }}
        icon={<FaTools />}
      >
        <h1 className="roadmap_header">The Preparation</h1>
        <p>
          Conduct Market Research, Define Project Scope and Goals, Assess
          Technical Requirements, Develop a Comprehensive Project Plan
        </p>
        <p>Secure Funding, Presale, Initial Marketing</p>
        <p>
          Build Decentralized Application (Dapp) and Web Interface, Implement
          Smart Contracts, Continuously Test and Debug
        </p>
        <p>Create Comprehensive Project Documentation</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          border: '2px solid #b88f31',
          color: '#b88f31',
          background: 'rgba(184,143,49,.086)',
        }}
        iconStyle={{ background: '#b88f31', color: '#fff' }}
        icon={<FaMountain />}
      >
        <h1 className="roadmap_header">The Foundation</h1>
        <p>Token Generation Event (TGE), Open trading on Uniswap</p>
        <p>Cryptocurrency Listing Sites (CoinMarketCap, CoinGecko)</p>
        <p>
          Launch of the Decentralized Application (dApp), Launch{' '}
          <span style={{ color: 'white' }}>The Mine</span>, Launch{' '}
          <span style={{ color: 'white' }}>The Company</span>
        </p>
        <p>Execution of Initial Marketing Initiatives</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          border: '2px solid #b88f31',
          color: '#b88f31',
          background: 'rgba(184,143,49,.086)',
        }}
        iconStyle={{ background: '#b88f31', color: '#fff' }}
        icon={<FaWpexplorer />}
      >
        <h1 className="roadmap_header">The Establishment</h1>
        <p>
          Formation of <span style={{ color: 'white' }}>The Mine</span> with an
          Initial Holding of Approximately $30,000
        </p>
        <p>
          Incorporation of <span style={{ color: 'white' }}>The Company</span>{' '}
          with Shareholder NFTs and an Initial Capitalization of Approximately
          $60,000
        </p>
        <p>
          Announcement, Development, and Launch of{' '}
          <span style={{ color: 'white' }}>The Exploration</span>
        </p>
        <p>
          Creation of the First Partner Mine and Ongoing Exploration for
          Additional Partner Mine Opportunities
        </p>
        <p>
          Announcement, Development and Launch of{' '}
          <span style={{ color: 'white' }}>The Refinery</span>
        </p>
        <p>Launch Staking Pools for Token Holders</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          border: '2px solid #b88f31',
          color: '#b88f31',
          background: 'rgba(184,143,49,.086)',
        }}
        iconStyle={{ background: '#b88f31', color: '#fff' }}
        icon={<FaExpand />}
      >
        <h1 className="roadmap_header">The Expansion</h1>
        <p>
          Expansion and incorporation of more Partner Mines, Add comprehensive
          information on all socials, and platforms.
        </p>
        <p>
          Release of the partner tier-system and other pivotal aspects of the
          alpha version of our partner system, Update dApp and Documentation
        </p>
        <p>
          Announcement, Development, and Launch of
          <span style={{ color: 'white' }}> The Deserted Mine</span>
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={{
          border: '2px solid #b88f31',
          color: '#b88f31',
          background: 'rgba(184,143,49,.086)',
        }}
        iconStyle={{ background: '#b88f31', color: '#fff' }}
        icon={<FaNetworkWired />}
      >
        <h1 className="roadmap_header">The Framework</h1>
        <p>Establish the vetted Partner Tier-system</p>
        <p>
          Announcement, Development, and Launch of the automated Partner Mine
          system for 'Degen' micro-caps
        </p>
        <p>
          Development, and launch of a Telegram Bot and a section of the dApp
          that would allow projects to set up their own Partner Mines,
          <span style={{ color: 'white' }}> The DIY Partner Mine Service</span>
        </p>
        <p>
          Development, and launch of a staking pool service for our partners in
          'The Refinery',
          <span style={{ color: 'white' }}>
            {' '}
            The DIY Partner Staking Service
          </span>
        </p>
        <p>
          Preparation, and accumulation of a portfolio of partners for the{' '}
          <span style={{ color: 'white' }}>The Investment Fund</span>
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={{
          border: '2px solid #b88f31',
          color: '#b88f31',
          background: 'rgba(184,143,49,.086)',
        }}
        iconStyle={{ background: '#b88f31', color: '#fff' }}
        icon={<FaMoneyBill />}
      >
        <h1 className="roadmap_header">The Investment</h1>
        <p>
          Launch and Formalize{' '}
          <span style={{ color: 'white' }}>The Investment Fund</span>
        </p>
        <p>
          Partner projects granted the prestigious{' '}
          <span style={{ color: 'white' }}>EGMC Golden Stamp of Approval </span>
          will be eligible for investments
        </p>
        <p>Release and disseminate a comprehensive investment plan</p>
        <p>Strategically accumulate a diversified portfolio of assets</p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--education"
        contentStyle={{
          border: '2px solid #b88f31',
          color: '#b88f31',
          background: 'rgba(184,143,49,.086)',
        }}
        iconStyle={{ background: '#b88f31', color: '#fff' }}
        icon={<FaSun />}
      >
        <h1 className="roadmap_header">The Horizon</h1>
        <p>
          The exceptionally ambitious and dedicated visionaries steering The
          Ethereum Gold Mining Company never cease their relentless pursuit of
          progress. They continually envision and strive to implement
          initiatives that enhance the company's potential for wealth generation
          and sustainable growth. As time unfolds, the future holds the key to
          revealing the remarkable possibilities on{' '}
          <span style={{ color: 'white' }}>The Horizon</span>
        </p>
      </VerticalTimelineElement>
    </VerticalTimeline>
  </>
);

export default Roadmap;
